import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

const Pricing = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHairHealthPricingCard {
        text1
        price1
        text2
        price2
        text3
        price3
        text4
        price4
        text5
        price5
        note
        heading
        shortDetail
        longDetail {
          longDetail
        }
        linkLabel
        linkUrl
      }
    }
  `);
  const {
    text1,
    price1,
    text2,
    price2,
    text3,
    price3,
    text4,
    price4,
    text5,
    price5,
    note,
    heading,
    shortDetail,
    longDetail,
    linkLabel,
    linkUrl,
  } = data.contentfulHairHealthPricingCard;
  return (
    <div className="our_pricing_main_section hair-pricing">
      <div className="container">
        <div className="our_pricing_inner_section">
          <div className="pricing_row">
            <div className="pricing_col">
              <div className="pricing_cost_list">
                <div className="pricing_wrapper">
                  <p className="cost_dec">{text1}:</p>
                  <p className="price_dose">
                    <span>${price1}/</span>dose
                  </p>
                </div>
                <div className="pricing_wrapper">
                  <p className="cost_dec">{text2}:</p>
                  <p className="price_dose">
                    <span>{price2}</span>
                  </p>
                </div>
                <div className="pricing_wrapper">
                  <p className="cost_dec">{text3}:</p>
                  <p className="price_dose">
                    <span>{price3}</span>
                  </p>
                </div>
                <div className="pricing_wrapper">
                  <p className="cost_dec">{text4}:</p>
                  <p className="price_dose">
                    <span>{price4}</span>
                  </p>
                </div>
                <div className="total_wrapper">
                  <p>{text5}</p>
                  <span>${price5}/dose</span>
                </div>
              </div>
              <p className="pricingDisclosure">{note}</p>
            </div>

            <div className="pricing_col main_pricing_details">
              <div className="our_pricing_details">
                <span>{shortDetail}</span>
                <h2>{heading}</h2>
                <p>{longDetail.longDetail}</p>
                <div className="pricing_btn">
                  <Link to="/hairloss-consult" className="common-btn">
                    {linkLabel}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
