import React from "react"
import Layout from "../components/layout"
import Pricing from "../components/product/pricing"
import HairPricing from "../components/hair/pricing"

const ProductPage = () => {
  return (
    <Layout>
      <div className="product_page pricingPage">
        <div className="main-wrapper product-page">
          <div className="main-content">
              <h1>  Erectile Dysfunction</h1>
            <Pricing />
            <div className="pricingWrapper">
              <h1 className="pricingHeader">  Hair Loss</h1>
              <HairPricing />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductPage
